import Vue from 'vue'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import DualListBox from "dual-listbox-vue";

export default {
    components: {
        vSelect,
        axios,
        Datepicker,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Balance auxiliaire",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            filtremodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            filtretexte : "",
            montant_total_debit: 0,
            montant_total_credit: 0,

            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'COMPTE': 'compte',
                'INTITULE': 'description',
                'RAN DEBIT': 'ran_credit',
                'RAN CREDIT': 'ran_debit',
                'ENCOURS DEBIT': 'montant_debit',
                'ENCOURS CREDIT': 'montant_credit',
                'CUMUL DEBIT': 'cumul_debit',
                'CUMUL CREDIT': 'cumul_credit'
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            disabledDates: {
                to: new Date(Date.now())
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            inclurebrouillard: "true",
            repporanouveau: "true",
            grandLivreData: [],
            printPDFValue:"",
            total_mouvement: {
                debit_report: 0,
                credit_report: 0,
                debit_encours: 0,
                credit_encours: 0,
                debit_cumul: 0,
                credit_cumul: 0
            }

        }
    },
    methods: {
        onChangeList: function ({ source, destination }) {
            this.DualListSource = source;
            this.DualListDestination = destination;
            this.filtretexte = JSON.stringify(destination);
        },
        onExport() {

            this.tabledata = this.grandLivreData;
            
            console.log(this.tabledata);
            this.json_data = this.tabledata;
            $("#excel-download").trigger("click");
            
        },
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
            this.setFilter();
        },
        openFiltreModal(){
            this.filtremodaltitle = "Filtre avancé - Choisir les comptes à afficher";
            this.$bvModal.show("filtremodal");
        },
        getAllCompte() {
            var that = this;
            axios.get(that.base_url+"/plancomptable/getall").then(response => {
                // console.log("===> Source ----");
                that.DualListSource = response.data;
                // that.DualListDestination = response.data;
                // console.log(that.DualListSource);
                // console.log("===> Source ----");
            });

        },
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');
            });

        },
        // notOverRange(date) {
        //     return date < new Date(this.date_exercice.date_debut) || date > new Date(this.date_exercice.date_fin);
        // },
        changeExercice() {
            if(this.$refs.afficherToutExercice.checked == true) {
                this.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.filtreDate.dateFin = this.date_exercice.date_fin;
            } else {
                this.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.filtreDate.dateFin = moment().format('DD/MM/YYYY');
            }
        },
        closeFiltreModal(){
            this.$bvModal.hide("filtremodal");
        },
        onRowClass(dataItem, index) {
            if(index =="") console.log("");
            return dataItem.brouillard_groupe_id % 2 == 0
              ? 'bg-gray'
              : 'bg-white'
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //console.log(this.listeCompte);
            });
        },
        inclurebrouillardFunc() {
            if(this.$refs.inclurebrouillards.checked == true) {
                this.inclurebrouillard = "true";
            } else {
                this.inclurebrouillard = "";
            }
        },
        repporanouveauFunc() {
            if(this.$refs.repporanouveaux.checked == true) {
                this.repporanouveau = "true";
            } else {
                this.repporanouveau = "";
            }
        },
        onSubmitSearch() {
            // this.closeFiltreModal();
            var that = this;
            axios.post(this.base_url+"/balance/fetchallaux", $("#onSubmitSearch").serialize()).then(function(response) {
                
                console.log(response.data);
                that.grandLivreData = response.data;
                // var debit_report = 0;
                // var credit_report = 0;
                // var debit_encours = 0;
                // var credit_encours = 0;
                // var debit_cumul = 0;
                // var credit_cumul = 0;
                // for(var i_count = 0; i_count < that.grandLivreData.length; i_count++) {
                //     debit_report += Number(that.grandLivreData[i_count]['ran_debit']);
                //     credit_report += Number(that.grandLivreData[i_count]['ran_credit']);
                //     debit_encours += Number(that.grandLivreData[i_count]['montant_debit']);
                //     credit_encours += Number(that.grandLivreData[i_count]['montant_credit']);
                //     debit_cumul += Number(that.grandLivreData[i_count]['cumul_debit']);
                //     credit_cumul += Number(that.grandLivreData[i_count]['cumul_credit']);
                // }


                // that.total_mouvement = {
                //     debit_report: debit_report,
                //     credit_report: credit_report,
                //     debit_encours: debit_encours,
                //     credit_encours: credit_encours,
                //     debit_cumul: debit_cumul,
                //     credit_cumul: credit_cumul
                // }

            });
        },
        onPrintGl1() {
            // this.closeFiltreModal();
            var that = this;

            axios.get(this.base_url+"/balance/printgl1").then(function(response) {
                
                that.printPDFValue = response.data;
                window.open(that.base_url+"/"+response.data);
                // console.log(that.base_url+"/"+response.data);
                // window.open(that.base_url+"/"+response.data);

            });
        }
    },
    computed: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.onSubmitSearch();
        
        //this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        this.initListCompte('');
        this.openFiltreModal();

    }
}